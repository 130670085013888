<template>
  <div>
    <EditDialog width="90%" :isShow.sync="costDetailDialog_show" v-if="type == 'BUSINESS_EXPENSE'">
      <div slot="title" class="title">商务报销详情</div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-descriptions direction="vertical" :column="6" border style="width: 100%">
          <el-descriptions-item label="单据编号">
            {{ costDetail.expenseNumber }}
          </el-descriptions-item>

          <el-descriptions-item label="申请人">
            {{ costDetail.createName }}
          </el-descriptions-item>

          <el-descriptions-item label="部门">
            {{ costDetail.deptName }}
          </el-descriptions-item>

          <el-descriptions-item label="领款人">
            {{ costDetail.payeeName }}
          </el-descriptions-item>

          <el-descriptions-item label="申请日期">
            {{ costDetail.taskDate | dateFormat }}
          </el-descriptions-item>

          <el-descriptions-item label="费用所属单位">
            {{ costDetail.companyType | dict(dictData.companyType) }}
          </el-descriptions-item>
          <el-descriptions-item
            :span="1"
            label-class-name="businessOutSource"
            content-class-name="businessOutSource"
          >
            <template slot="label">
              <span style="color: #000">费用类别</span>
            </template>
            <span style="color: #000; font-size: 14px">商务费用报销</span>
          </el-descriptions-item>

          <el-descriptions-item label="付款方式" :span="2">
            <el-radio-group disabled v-model="costDetail.applyExpenseType">
              <el-radio label="REGULAR_PAYMENT_REQUEST">正常付款申请</el-radio>
              <el-radio label="ADVANCE_PAYMENT">冲还预付款</el-radio>
            </el-radio-group>
          </el-descriptions-item>

          <el-descriptions-item label="支出类型" :span="2">
            <el-radio-group disabled v-model="costDetail.disbursementType">
              <el-radio label="THROUGH_RATIFICATION_IMPLEMENT">经批准开展</el-radio>
              <el-radio label="UNDEFINED_RATIFICATION_IMPLEMENT">经批准开展的面上支出</el-radio>
              <el-radio label="COORDINATE_IMPLEMENT">配合开展</el-radio>
            </el-radio-group>
          </el-descriptions-item>

          <el-descriptions-item label="报销方式" :span="2">
            <el-radio-group disabled v-model="costDetail.expenseMode">
              <el-radio label="SELF_FILING">直接报销</el-radio>
              <el-radio label="REGISTRATION_EXPENDITURE_DOCUMENT">由登记单转为报销 </el-radio>
            </el-radio-group>
            <!-- <el-button
              v-show="costDetail.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
              size="medium"
              type="warning"
              style="margin-left: 10%; padding: 5px"
              @click="registrationIsShowFn"
              >原登记单</el-button
            > -->
          </el-descriptions-item>
          <el-descriptions-item v-if="costDetail.remark">
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
            </template>
            {{ costDetail.remark }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="" :column="1" border style="margin-top: 20px">
          <el-descriptions-item labelStyle="width:100px;" :span="1">
            <template slot="label"> 商务名 </template>
            <span style="display: inline-block; line-height: 40px">{{
              costDetail.businessName
            }}</span>
            <!-- <el-button
              style="position: absolute; right: 2%; margin-right: 5px"
              type="success"
              v-if="
                costDetail.status == 10 || costDetail.status == 100
              "
              @click="exportFileZipFn"
              :loading="down_loading"
            >
              全部附件下载
            </el-button> -->
          </el-descriptions-item>
        </el-descriptions>
        <el-form class="column3 form_box table" label-position="top" style="width: 100%">
          <el-table :data="costDetail.detailList" id="table" :row-style="{ height: '80px' }">
            <el-table-column
              prop="taskDate"
              label="日期"
              width="120"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="typeOne" label="费用科目一" :show-overflow-tooltip="false">
              <template slot="header">
                费用科目一
                <div>
                  <!-- <el-button size="mini" type="warning" @click="subjectIsShow = true"
                    >科目说明</el-button
                  > -->
                </div>
              </template>
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeOne" code="CBFYZC" />
              </template>
            </el-table-column>
            <el-table-column prop="typeTwo" label="费用科目二" :show-overflow-tooltip="false">
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeTwo" :code="scope.row.typeOne" />
              </template>
            </el-table-column>
            <el-table-column prop="typeThree" label="费用科目三" :show-overflow-tooltip="false">
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeThree" :code="scope.row.typeTwo" />
              </template>
            </el-table-column>

            <el-table-column
              prop="cost"
              label="金额 (元)"
              width="120"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.cost | applyAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              scoped-slot
              prop="contentDetail"
              :label="'事由附件详细说明'"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <el-input
                  disabled
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 5 }"
                  placeholder="请输入"
                  v-model="scope.row.contentDetail"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column align="left" label="附件上传" :show-overflow-tooltip="false">
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <a
                    v-if="isShowType(n)"
                    style="text-decoration: revert; color: #409eff; padding-right: 8px"
                    target="_blank"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>
                  <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>

        <el-descriptions title="" :column="3" border>
          <el-descriptions-item
            label="原登记单总金额"
            v-if="costDetail.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
            labelStyle="width:10%"
          >
            {{ costDetail.registrationExpenditureTotalCost | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="合计" labelStyle="width:10%">
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" labelStyle="width:10%">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
        </el-descriptions>

        <div style="height: 40px"></div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="closeBusinessPerformanceDetailDialog"
          >返回</el-button
        >
      </div>
    </EditDialog>

    <EditDialog
      width="90%"
      :isShow.sync="costDetailDialog_show"
      v-else-if="type == 'BUSINESS_REGISTRATION_EXPENDITURE'"
    >
      <div slot="title" class="title">商务支出登记详情</div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-descriptions direction="vertical" :column="columnCount" border style="width: 100%">
          <el-descriptions-item label="单据编号">
            {{ costDetail.expenseNumber }}
          </el-descriptions-item>

          <el-descriptions-item label="填报人">
            {{ costDetail.createName }}
          </el-descriptions-item>

          <el-descriptions-item label="申请日期">
            {{ costDetail.taskDate | dateFormat }}
          </el-descriptions-item>

          <el-descriptions-item label="申请人">
            {{ costDetail.applyName }}
          </el-descriptions-item>

          <el-descriptions-item label="发生日期">
            {{ costDetail.occurDate | dateFormat }}
          </el-descriptions-item>

          <el-descriptions-item
            label="登记类型"
            label-class-name="businessOutSource"
            content-class-name="businessOutSource"
          >
            {{ costDetail.registrationType | dict(dictData.registrationType) }}
          </el-descriptions-item>

          <el-descriptions-item label="招待等级" v-if="costDetail.registrationType == 'KITCHEN'">
            <span>
              {{ costDetail.hospitalityGrade | dict(dictData.hospitalityGrade) }}
            </span>
          </el-descriptions-item>

          <el-descriptions-item label="招待人数" v-if="costDetail.registrationType == 'KITCHEN'">
            <span>
              {{ costDetail.numberPeople + '人' }}
            </span>
          </el-descriptions-item>

          <el-descriptions-item label="申请单位">
            <span>
              {{ costDetail.companyType | dict(dictData.companyType) }}
            </span>
          </el-descriptions-item>

          <el-descriptions-item
            label-class-name="businessOutSource"
            content-class-name="businessOutSource"
          >
            <template slot="label">
              <span style="color: #000">费用类型</span>
            </template>
            {{ costDetail.expenseType | dict(dictData.expenseType) }}
          </el-descriptions-item>

          <el-descriptions-item :span="1">
            <template slot="label"> 支出类型 </template>
            <span>
              {{ costDetail.disbursementType | dict(dictData.disbursementType) }}
            </span>
          </el-descriptions-item>

          <el-descriptions-item :span="columnName" contentStyle="min-width:300px;">
            <template slot="label">
              <span v-if="costDetail.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'"
                >商务说明</span
              >
              <span v-else>费用所属商务</span>
            </template>
            <template v-if="costDetail.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'">
              <span style="display: inline-block; line-height: 40px; height: 40px">{{
                costDetail.businessContent
              }}</span>
            </template>
            <template v-else>
              <span>
                {{ costDetail.expenseBelongName }}
              </span>
            </template>
          </el-descriptions-item>

          <el-descriptions-item label="支出说明" :span="6">
            <span>{{ costDetail.expenditureExplain }}</span>
          </el-descriptions-item>
        </el-descriptions>

        <el-form
          class="column3 form_box table"
          label-position="top"
          style="width: 100%; margin-top: 20px"
        >
          <el-table :data="costDetail.detailList" id="table" :row-style="{ height: '80px' }">
            <el-table-column prop="cost" label="金额 (元)" :show-overflow-tooltip="false">
              <template slot-scope="scope">
                <span>{{ scope.row.cost | applyAmount }}</span>
              </template>
            </el-table-column>

            <el-table-column
              scoped-slot
              prop="contentDetail"
              :label="'事由附件详细说明'"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <el-input
                  disabled
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 5 }"
                  placeholder="请输入"
                  v-model="scope.row.contentDetail"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column align="left" label="附件上传" :show-overflow-tooltip="false">
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <a
                    v-if="isShowType(n)"
                    style="text-decoration: revert; color: #409eff; padding-right: 8px"
                    target="_blank"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>
                  <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>

        <el-descriptions title="" :column="3" border>
          <el-descriptions-item label="合计" labelStyle="width:15%">
            {{ amount | applyAmount }}
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" labelStyle="width:15%">
            {{ amount | numberParseChina }}
          </el-descriptions-item>
        </el-descriptions>

        <div style="height: 40px"></div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="closeBusinessPerformanceDetailDialog"
          >返回</el-button
        >
      </div>
    </EditDialog>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>
  </div>
</template>

<script>
export default {
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
  },
  data() {
    return {
      img_index: 0, //附件图片预览下标
      dialogVisible: false, //图片预览
      filepath: process.env.VUE_APP_FILEPATH,

      dictData: {
        companyType: [],
        registrationType: [],
        hospitalityGrade: [],
        expenseType: [],
        disbursementType: [],
      },
    }
  },
  props: {
    costDetailDialog_show: {
      type: Boolean,
      default: false,
      required: true,
    },

    type: {
      type: String,
      default: '',
      required: true,
    },

    costDetail: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },

    imgList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('REGISTRATION_TYPE', true)
      .then(res => {
        this.dictData.registrationType = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('HOSPITALITY_GRADE', true)
      .then(res => {
        this.dictData.hospitalityGrade = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('EXPENSE_TYPE', true)
      .then(res => {
        this.dictData.expenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true)
      .then(res => {
        this.dictData.disbursementType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    // 报销单金额合计
    amount() {
      let sum = 0
      if (this.costDetail.detailList) {
        sum = this.costDetail.detailList.reduce((total, item) => (total += Number(item.cost)), 0)
      }
      return sum
    },

    // 获取当前描述列表的列数
    columnCount() {
      if (this.costDetail.registrationType == 'KITCHEN' && this.costDetail.expenseNumber) {
        // 厨房登记且存在单据编号
        return 8
      } else if (
        this.costDetail.registrationType == 'INVENTORY_KEEPER' &&
        this.costDetail.expenseNumber
      ) {
        // 库管登记且存在单据编号
        return 6
      } else if (
        this.costDetail.registrationType == 'EXTERNAL_SPENDING' &&
        !this.costDetail.expenseNumber
      ) {
        // 外部登记且存在单据编号
        return 5
      } else if (
        this.costDetail.registrationType == 'EXTERNAL_SPENDING' &&
        this.costDetail.expenseNumber
      ) {
        // 外部登记且不存在单据编号
        return 6
      }
    },
    columnName() {
      switch (this.columnCount) {
        case 4:
          // 新增外部登记只占一格
          return 1
        case 5:
          return 2
        case 6:
          return 3
        case 8:
          return 5
      }
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },

    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        } else if (v.id === n.id || (v.fileName === n.fileName && v.filePath === n.filePath)) {
          this.img_index = i
        }
      })
      this.dialogVisible = true
    },

    // 关闭详情弹框
    closeBusinessPerformanceDetailDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}

/deep/.businessOutSource {
  //费用类型背景色
  background-color: #b2f3fe !important;
  border: 1px solid #ddd !important;
}
</style>
